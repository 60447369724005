import "twin.macro"
import React from "react"
import AppIcon from "@atoms/icon"
import "./button.css"

const Button = ({
  children,
  prefix,
  suffix,
  Tag = "button",
  theme = "primary",
  className,
  ...props
}) => {
  return (
    <Tag className={`app-button app-button--${theme} ${className}`} {...props}>
      {prefix && <AppIcon name={prefix} tw="w-6 h-6 mr-2 flex-shrink-0" />}
      {children}
      {suffix && <AppIcon name={suffix} tw="w-4 h-4 ml-2 flex-shrink-0" />}
    </Tag>
  )
}

export default Button
