import { navigate } from "gatsby"
import { useEffect } from "react"
import { prefersLangToken } from "./config"
import { getRedirectLanguage } from "./helpers"
export const useLocaleRedirect = function (localisedSlugs, currentLocale) {
  useEffect(() => {
    const locale =
      localStorage?.getItem?.(prefersLangToken) || getRedirectLanguage()
    if (locale && locale !== currentLocale && currentLocale === "en") {
      const localSlug = localisedSlugs.find(x => x.locale === locale)
      if (localSlug) {
        navigate(localSlug.to, { replace: true })
      }
    }
  })
}
