import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import AppButton from "@atoms/button"
import AppIcon from "@atoms/icon"
import tw, { css } from "twin.macro"
import { injectIntl, FormattedMessage } from "react-intl"
import { prefixLink } from "../../i18n/helpers"
import Sandbox from "../atoms/sandbox"

const footerLight = css`
  background: white;
`
const footerDark = css`
  background: #1d1d1d;
  .app-footer-links,
  .app-footer-cta__text {
    color: #dadada;
  }
  .app-footer-cta__title {
    color: white;
  }
`

const query = graphql`
  fragment FooterFragment on StrapiFooter {
    title
    text
    action {
      id
      external
      icon_prefix
      icon_suffix
      name
      target
      theme
      url
    }
    icon
  }

  fragment FooterGetInvolvedFragment on StrapiGetInvolved {
    locale
    slug
    name
  }

  fragment FooterPrivacyFragment on StrapiPrivacy {
    slug
    name
  }

  query Footer {
    privacy: strapiPrivacy(locale: { eq: "en" }) {
      ...FooterPrivacyFragment
    }
    gi_en: strapiGetInvolved(locale: { eq: "en" }) {
      ...FooterGetInvolvedFragment
    }
    gi_de: strapiGetInvolved(locale: { eq: "de" }) {
      ...FooterGetInvolvedFragment
    }
    gi_es: strapiGetInvolved(locale: { eq: "es" }) {
      ...FooterGetInvolvedFragment
    }
    gi_fr: strapiGetInvolved(locale: { eq: "fr" }) {
      ...FooterGetInvolvedFragment
    }
    gi_it: strapiGetInvolved(locale: { eq: "it" }) {
      ...FooterGetInvolvedFragment
    }
    gi_ko: strapiGetInvolved(locale: { eq: "ko" }) {
      ...FooterGetInvolvedFragment
    }
    gi_pl: strapiGetInvolved(locale: { eq: "pl" }) {
      ...FooterGetInvolvedFragment
    }
    gi_pt: strapiGetInvolved(locale: { eq: "pt" }) {
      ...FooterGetInvolvedFragment
    }
    gi_sv: strapiGetInvolved(locale: { eq: "sv" }) {
      ...FooterGetInvolvedFragment
    }
    gi_tr: strapiGetInvolved(locale: { eq: "tr" }) {
      ...FooterGetInvolvedFragment
    }
    footer_en: strapiFooter(locale: { eq: "en" }) {
      ...FooterFragment
    }
    footer_de: strapiFooter(locale: { eq: "de" }) {
      ...FooterFragment
    }
    footer_es: strapiFooter(locale: { eq: "es" }) {
      ...FooterFragment
    }
    footer_fr: strapiFooter(locale: { eq: "fr" }) {
      ...FooterFragment
    }
    footer_it: strapiFooter(locale: { eq: "it" }) {
      ...FooterFragment
    }
    footer_ko: strapiFooter(locale: { eq: "ko" }) {
      ...FooterFragment
    }
    footer_pl: strapiFooter(locale: { eq: "pl" }) {
      ...FooterFragment
    }
    footer_pt: strapiFooter(locale: { eq: "pt" }) {
      ...FooterFragment
    }
    footer_sv: strapiFooter(locale: { eq: "sv" }) {
      ...FooterFragment
    }
    footer_tr: strapiFooter(locale: { eq: "tr" }) {
      ...FooterFragment
    }
  }
`

const Footer = ({ path, intl }) => {
  const locale = intl.locale
  return (
    <StaticQuery
      query={query}
      render={data => {
        const footer = data?.[`footer_${locale}`] || data?.footer_en
        const gi = data?.[`gi_${locale}`] || data?.gi_en
        const privacy = data?.privacy
        if (!footer || !gi || !privacy) return <></>
        const isLight =
          path.split("/").join("") === (intl.locale === "en" ? "" : intl.locale)
        return (
          <footer
            className={`${isLight ? "tear-t-lg-white" : "tear-t-lg-dark"}`}
            tw="relative"
            css={[isLight ? footerLight : footerDark]}
          >
            <div tw="max-w-screen-xl mx-auto px-6 pt-8 pb-12 text-center xl:(pt-12 px-12)">
              <div tw="items-center justify-between lg:flex">
                <div tw="items-start sm:flex">
                  {footer.icon && (
                    <AppIcon
                      name={footer.icon}
                      tw="w-24 h-24 text-brand-blue block mx-auto flex-shrink-0 xl:(w-28 h-28)"
                    />
                  )}
                  <div tw=" sm:text-left sm:pl-4">
                    {footer.title && (
                      <h3
                        className="app-footer-cta__title"
                        tw="text-4xl font-display font-bold"
                      >
                        {footer.title}
                      </h3>
                    )}
                    <Sandbox
                      className="app-footer-cta__text"
                      tw="mt-2"
                      dangerouslySetInnerHTML={{ __html: footer.text }}
                    />
                    {gi && (
                      <Link
                        css={[
                          tw`uppercase`,
                          isLight ? tw`text-brand-blue` : tw`text-brand-green`,
                        ]}
                        to={prefixLink(`/${gi.slug}`, intl.locale)}
                        title={gi.name}
                        activeStyle={{ visibility: "hidden" }}
                      >
                        <FormattedMessage
                          id="find_out_more"
                          defaultMessage="Find out more"
                        />
                      </Link>
                    )}
                  </div>
                </div>
                <div tw="leading-none flex flex-wrap items-center justify-center sm:ml-24 sm:justify-start lg:flex-grow xl:flex-nowrap">
                  {Array.isArray(footer.action) &&
                    footer.action.map(action => (
                      <AppButton
                        key={action.id}
                        Tag={action.url ? "a" : "button"}
                        tw="my-2 mx-4 xl:my-0"
                        suffix={action.icon_suffix}
                        prefix={action.icon_prefix}
                        theme={action.theme}
                        href={action.url}
                        target={action.target === "BLANK" ? "_BLANK" : ""}
                      >
                        {action.name}
                      </AppButton>
                    ))}
                </div>
              </div>
              <div
                className="app-footer-links"
                tw="mt-8 text-sm items-center sm:flex sm:ml-28 sm:mt-12"
              >
                <p>© Fairtrade International {new Date().getFullYear()}</p>
                {privacy && privacy.slug && (
                  <Link
                    key="item.id"
                    tw="mt-4 block sm:mt-0 sm:ml-12"
                    to={`/${privacy.slug}`}
                  >
                    {privacy.name}
                  </Link>
                )}
              </div>
            </div>
          </footer>
        )
      }}
    ></StaticQuery>
  )
}

export default injectIntl(Footer)
