export const DRAWER_BREAKPOINTS = [
  {
    query: "(min-width: 1650px)",
    value: "1280px",
  },
  {
    query: "(min-width: 1280px)",
    value: "1024px",
  },
  {
    query: "(min-width: 1024px)",
    value: "860px",
  },
  {
    query: "(min-width: 768px)",
    value: "90%",
  },
]
export const DRAWER_BASE_WIDTH = "80%"
