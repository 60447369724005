import { useEffect, useState, useRef } from "react"
import { createPortal } from "react-dom"

const Portal = ({ id = "portal-outlet", children }) => {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    ref.current = document.querySelector(`#${id}`)
    if (!ref.current) {
      const div = document.createElement("div")
      div.setAttribute("id", id)
      document.body.appendChild(div)
      ref.current = div
    }
    setMounted(true)
  }, [id])

  return mounted ? createPortal(children, ref.current) : null
}

export default Portal
