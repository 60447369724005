import React from "react"
import tw, { css } from "twin.macro"
import Sandbox from "../../atoms/sandbox"
import DrawerStory from "./drawer-story"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

const DrawerContent = ({ data }) => {
  const stories = data.stories.sort((a, b) => a.order - b.order)
  return (
    <SimpleBar
      tw="overflow-y-auto overflow-x-hidden h-full"
      autoHide={true}
      css={css`
        .simplebar-scrollbar:before {
          ${tw`bg-gray-300`}
        }
      `}
    >
      <div tw="p-6 sm:(px-12) lg:(py-12 px-20)">
        <h3 tw="font-display text-2xl md:(text-4xl) lg:(text-5xl)">
          {data.title}
        </h3>
        <Sandbox
          tw="text-sm mt-2"
          dangerouslySetInnerHTML={{ __html: data.text }}
        ></Sandbox>
        <div tw="-mx-4 md:(flex flex-wrap justify-center) lg:(-mx-6)">
          {stories.map((story, index) => (
            <div
              tw="p-4 md:(w-1/3) lg:(px-6)"
              css={[
                index % 2 !== 0 &&
                  tw`
                border-gray-200 border-t border-b
                md:(border-l border-r)
                md:border-t-0
                md:border-b-0
            `,
              ]}
              key={story.id}
            >
              <DrawerStory story={story} />
            </div>
          ))}
        </div>
      </div>
    </SimpleBar>
  )
}

export default DrawerContent
