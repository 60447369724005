import React, { useReducer } from "react"
export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const BASE_STATE = {
  video: null,
  showEpisodesDrawer: true,
  selectedStory: null,
  header: null,
  locales: [],
  activeLocales: [],
  sitemap: null,
}

export const ACTIONS = {
  setVideo: "SET_VIDEO",
  selectStory: "SELECT_STORY",
  hideEpisodeDrawer: "HIDE_EP_DRAWER",
  showEpisodesDrawer: "SHOW_EP_DRAWER",
}

export const createAction = (type, payload = null) => {
  return { type, payload }
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.setVideo: {
      return {
        ...state,
        video: action.payload,
      }
    }
    case ACTIONS.hideEpisodeDrawer: {
      return {
        ...state,
        showEpisodesDrawer: false,
      }
    }
    case ACTIONS.showEpisodesDrawer: {
      return {
        ...state,
        showEpisodesDrawer: false,
      }
    }
    case ACTIONS.selectStory: {
      return {
        ...state,
        selectedStory: action.payload,
      }
    }
    default:
      throw new Error("Unknown action")
  }
}

const GlobalContextProvider = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...BASE_STATE,
    ...initialState,
  })
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
