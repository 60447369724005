import React, { useContext, useMemo, useState } from "react"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"
import { motion, AnimatePresence } from "framer-motion"
import { prefersLangToken } from "../../../i18n/config"
import { prefixLink } from "../../../i18n/helpers"
import AppIcon from "@atoms/icon"
import Helmet from "react-helmet"
import tw, { css } from "twin.macro"
import { useLocaleRedirect } from "../../../i18n/use-locale-redirect"
import { GlobalStateContext } from "../../../utils/global-context"

const getPath = (locale, leaf) => {
  if (!leaf || !leaf.slug) return prefixLink("/", locale)
  if (leaf.key === "stories" && locale === "en") return `/story/${leaf.slug}`
  return prefixLink(`/${leaf.slug}`, locale)
}
const getLocalisedSlug = (items, locale, currentKey) => {
  const item = items.find(item => item.locale === locale)
  if (Array.isArray(currentKey) && Array.isArray(item[currentKey[0]])) {
    return {
      key: currentKey[0],
      slug: item?.[currentKey[0]]?.find?.(el =>
        el.localizations?.some?.(loc => loc.id === currentKey[1])
      )?.slug,
    }
  }
  return {
    key: currentKey,
    slug: item?.[currentKey]?.slug,
  }
}
const LocaleSwitch = ({ path }) => {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const { header, activeLocales } = useContext(GlobalStateContext)

  const localisedSlugs = useMemo(() => {
    const lastSegment = path.replace(/\/$/, "").split("/").pop()
    let currentKey = null
    const items = header?.items || []

    const tree = items.find(item => item.locale === intl.locale)
    for (const key in tree) {
      if (Object.hasOwnProperty.call(tree, key)) {
        const item = tree[key]
        if (Array.isArray(item)) {
          // eslint-disable-next-line no-loop-func
          item.some(_item => {
            if (_item.slug === lastSegment) {
              currentKey = [key, _item.id]
              return true
            }
            return false
          })
        } else {
          if (item?.slug === lastSegment) {
            currentKey = key
          }
        }
      }
    }
    return activeLocales
      .filter(x => x !== intl.locale)
      .map(locale => ({
        locale,
        to: getPath(locale, getLocalisedSlug(items, locale, currentKey)),
      }))
  }, [path, intl.locale, activeLocales, header])

  const handleNavigation = (event, { locale, to }) => {
    if (typeof event?.preventDefault === "function") {
      event.preventDefault()
      localStorage.setItem(prefersLangToken, locale)
      navigate(to)
    }
  }

  useLocaleRedirect(localisedSlugs, intl.locale)

  return (
    <>
      <Helmet>
        {localisedSlugs.map(x => (
          <link
            key={x.locale}
            rel="alternate"
            hrefLang={x.locale}
            href={`${process.env.GATSBY_SITE_URL}${x.to}`}
          />
        ))}
      </Helmet>
      <div tw="relative text-white mr-4">
        <button
          onClick={() => setShow(!show)}
          tw="uppercase text-brand-green inline-flex items-center focus:outline-none"
        >
          <span>{intl.locale}</span>
          <AppIcon
            name="arrow-right"
            css={[
              tw` transition-transform block text-white w-3 h-3 ml-2`,
              css`
                transform: rotate(90deg);
              `,
              show &&
                css`
                  transform: rotate(-90deg);
                `,
            ]}
          />
        </button>
        <AnimatePresence>
          {show && (
            <motion.div
              tw="absolute top-full right-0 -ml-6 py-4 px-2 bg-brand-dark"
              initial={{ y: "100%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "100%", opacity: 0 }}
            >
              <ul tw="w-24 grid grid-cols-2 text-center content-center">
                {localisedSlugs.map(x => (
                  <li key={x.locale}>
                    <a
                      href={x.to || ""}
                      tw="uppercase block p-1 hover:text-brand-green"
                      onClick={event => handleNavigation(event, x)}
                    >
                      {x.locale}
                    </a>
                  </li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}

export default LocaleSwitch
