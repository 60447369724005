import { useContext, useMemo } from "react"
import { GlobalStateContext } from "../../../utils/global-context"
import { useIntl } from "react-intl"
import _omit from "lodash/omit"

const useDrawerData = data => {
  const intl = useIntl()
  const globalState = useContext(GlobalStateContext)
  const localisedData =
    data.localisedData.find(({ locale }) => locale === intl.locale) ||
    data.localisedData.find(({ locale }) => locale === "en")
  localisedData.stories = localisedData.stories.map(story => {
    const _story = data.stories.find(({ compositeId }) =>
      compositeId.includes(story.id)
    )
    return {
      ...story,
      ..._omit(_story, ["id", "compositeId"]),
      episodes: story.episodes.map(episode => {
        const _episode = data.episodes.find(({ compositeId }) =>
          compositeId.includes(episode.id)
        )
        return {
          ...episode,
          ..._omit(_episode, ["id", "compositeId", "formatted_release_date"]),
        }
      }),
    }
  })
  const selectedStory = useMemo(() => {
    if (!localisedData) return {}
    return localisedData.stories.find(
      story => story.id === globalState.selectedStory
    )
  }, [localisedData, globalState.selectedStory])

  return { selectedStory, localisedData }
}

export default useDrawerData
