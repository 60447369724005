import React from "react"
import { ReactComponent as ShareIcon } from "@assets/icons/share.svg"
import { ReactComponent as Instagram } from "@assets/icons/instagram.svg"
import { ReactComponent as Close } from "@assets/icons/close.svg"
import { ReactComponent as ArrowRight } from "@assets/icons/arrow-right.svg"
import { ReactComponent as Play } from "@assets/icons/play.svg"
import { ReactComponent as PlayArrow } from "@assets/icons/play_arrow.svg"
import { ReactComponent as OpenInFull } from "@assets/icons/open_in_full.svg"
import { ReactComponent as ArrowBack } from "@assets/icons/arrow_back.svg"
import { ReactComponent as ArrowFw } from "@assets/icons/arrow_forward.svg"
import { ReactComponent as DragHandle } from "@assets/icons/drag_handle.svg"
import FistIcon from "../icons/fist"
import Camera from "../icons/camera"
import CoffeeIcon from "../icons/coffee"
import Coconut from "../icons/coconut"
import RiceIcon from "../icons/rice"
import BananaIcon from "../icons/banana"
const normaliseIconName = icon => {
  if (typeof icon === "string") {
    return icon.replace(/_/g, "-")
  }
  return null
}

const getIconComponent = name => {
  switch (normaliseIconName(name)) {
    case `fist`:
      return FistIcon
    case `rice`:
      return RiceIcon
    case `share`:
      return ShareIcon
    case `coffee`:
      return CoffeeIcon
    case `banana`:
      return BananaIcon
    case `coconut`:
      return Coconut
    case `instagram`:
      return Instagram
    case `arrow-right`:
      return ArrowRight
    case `close`:
      return Close
    case `play`:
      return Play
    case `camera`:
      return Camera
    case `play-arrow`:
      return PlayArrow
    case `open-in-full`:
      return OpenInFull
    case `arrow-forward`:
      return ArrowFw
    case `arrow-back`:
      return ArrowBack
    case `drag-handle`:
      return DragHandle
    default:
      return ``
  }
}

const Icon = ({ name, className, ...props }) => {
  const Cmp = getIconComponent(name)
  if (!Cmp) return ""
  return (
    <span className={`app-icon ${className || ""}`} aria-hidden={true}>
      <Cmp {...props} />
    </span>
  )
}

export default Icon
