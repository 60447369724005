import React from "react"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { childVariants, parentVariants } from "./variants"

const BananaIcon = () => {
  return (
    <InView threshold={0.5}>
      {({ ref, inView }) => (
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          version="1.1"
          viewBox="0 0 200 200"
          xmlSpace="preserve"
          fill="currentColor"
          ref={ref}
          variants={parentVariants}
          initial="out"
          animate={inView ? "in" : "out"}
        >
          <motion.path
            variants={childVariants}
            d="M97 11.5h4.2v29.8H97zM134.1 119.9c9.2-19.4 10.9-42.3 5.7-60.6-3.6-13.3-14.8-21.7-21.7-10.7-2 3.2-3.7 7.6-5.9 13.2-3.3 8.5-7.4 19.3-14.5 30-3.3-3.1-7.4-5.8-11.3-5.2-8.1 1.3-30.2 16.8-33.6 24.8-1 2.3-.4 3.9.2 4.8 3 4.1 10.4 2.2 15.7 1.6 1.7-.2 3.5-.5 5.4-.7-7.1 5.8-13.5 9.8-18.6 13-4.9 3.1-8.7 5.5-11.1 8.2-2.8 3.2-3.5 6.7-2.1 10.9 1.6 5.1 6.7 8.9 14.3 10.7 18.4 3.7 39.2-2.4 56-14.6 1.4 6.7 2.8 14.9 6.2 15.4 9.5 2.7 18.3-25.8 19.3-32.2.4-2.1-.1-5.3-4-8.6m-17.5-56.5c2-5.2 3.7-9.6 5.4-12.4 1.5-2.3 2.7-2.9 3.7-2.9 4.3.4 7.3 5 9.7 12.4 4.1 13.2 4.1 36.9-5.1 56.6-6.3-3.6-15.6-6.2-20.4-4.9-.6-5.4-4.8-12.2-8.8-17 7.7-11.4 12-22.8 15.5-31.8m-48.5 49.9c-3.5.4-9.1 1.5-11.1.4 1-5.4 24-21.9 30.1-22.5 6.5-.9 19.3 17.7 18.1 22.4-.2.1-.8.3-2.7.3-1.8-.1-4.2-.4-6.7-.8-3.8-.5-8.1-1.2-12.5-1.2-5.5 0-10.7.7-15.2 1.4M58 134c6.4-4 14.6-9.1 23.9-17.5 1.9 0 3.8.1 5.7.2-3.7 2-7.7 5.5-11.2 11.3-3.2 5.3-8.5 8.1-15.8 8.2-2 0-3.8-.2-5.4-.4.8-.6 1.8-1.2 2.8-1.8m-11.3 13.8c-1.2-3.7-.1-5.9 4.1-9 2 .5 5.4 1.2 9.3 1.2 6.6 0 14.6-1.9 19.5-9.9 5.4-8.9 11.9-11.5 16.4-12.2 2.8.4 5.2.7 7.2.7.5 0 1.5 0 2.6-.2 0 .3 0 .6.1 1-4.4 0-10.3 1.2-14.7 4.8-4.1 3.3-6 8.1-5.6 13.9.4 5.5-.8 9.8-3.5 12.8-2.7 2.9-7.1 4.8-13 5.6-10 .1-19.6-1.9-22.4-8.7m40.3 5.3c-1 .3-2 .6-3.1.9.3-.3.6-.5.8-.8 3.5-3.8 5-9.1 4.5-15.6-.4-4.6 1.1-8.2 4.2-10.7 3.7-3 9.2-4 12.9-3.9.6 2.6 1.5 5.2 2.5 8.6.9 2.8 1.9 5.8 2.6 8.7-8.8 7-17.8 10.8-24.4 12.8m46.6-25.3c-.4 5-8.4 26.7-13.2 28.5-.9-1.2-1.9-6.1-2.7-9.7-.7-3.3-1.5-7.1-2.6-10.7-1.4-4.9-3.9-11.6-4.6-15.8v-.3c-.3-1.9 0-2.8.3-3 2.6-1.2 13 1.1 19.2 5.7 1.5 1.1 3.9 3.2 3.6 5.3"
          />
          <motion.g variants={childVariants}>
            <path
              d="M131.3 24.8h30.9V29h-30.9z"
              transform="rotate(-53.687 146.743 26.885)"
            />
            <path
              d="M51.1 24.8h4.2v32.9h-4.2z"
              transform="rotate(-29.105 53.158 41.29)"
            />
            <path
              d="M17.7 105.8h28.7v4.2H17.7z"
              transform="rotate(-14.557 32.063 107.862)"
            />
            <path
              d="M154.3 80.4H183v4.2h-28.7z"
              transform="rotate(-14.557 168.647 82.506)"
            />
          </motion.g>
          <motion.path
            variants={childVariants}
            d="M25.6 79.4l3.6 1.6c.2.1.3.2.3.4l1.4 4c.2.6.9.6 1.1 0l1.4-4c.1-.2.2-.3.3-.4l3.6-1.6c.5-.2.5-1 0-1.3l-3.6-1.6c-.2-.1-.3-.2-.3-.4l-1.4-4c-.2-.6-.9-.6-1.1 0l-1.4 4c-.1.2-.2.3-.3.4l-3.6 1.6c-.5.3-.5 1.1 0 1.3M42.4 69.7c1.4-.6 2.1-2.3 1.5-3.7-.6-1.5-2.3-2.1-3.7-1.5-1.5.6-2.1 2.3-1.5 3.7.6 1.4 2.3 2.1 3.7 1.5M155.1 58.7l3.6-1.6c.5-.2.5-1 0-1.3l-3.6-1.6c-.2-.1-.3-.2-.3-.4l-1.4-4c-.2-.6-.9-.6-1.1 0l-1.4 4c-.1.2-.2.3-.3.4l-3.6 1.6c-.5.2-.5 1 0 1.3l3.6 1.6c.2.1.3.2.3.4l1.4 4c.2.6.9.6 1.1 0l1.4-4c.1-.2.2-.4.3-.4M163.8 47.4c1.4-.6 2.1-2.3 1.5-3.7-.6-1.5-2.3-2.1-3.7-1.5-1.5.6-2.1 2.3-1.5 3.7.5 1.4 2.2 2.1 3.7 1.5"
          />
        </motion.svg>
      )}
    </InView>
  )
}

export default BananaIcon
