import React, { useContext } from "react"
import tw, { css } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FormattedMessage } from "react-intl"
import AppIcon from "@atoms/icon"
import Sandbox from "@atoms/sandbox"
import { ACTIONS, GlobalDispatchContext } from "../../../utils/global-context"
import { graphql } from "gatsby"

const EpisodeThumb = ({ episode, fallback_url }) => {
  const image = getImage(episode.poster.localFile)
  const dispatch = useContext(GlobalDispatchContext)
  const canWatchNow = episode.vimeo_url
  return (
    <div tw="mb-4">
      <div tw="flex text-xs mb-1">
        <p tw="uppercase flex-grow font-bold font-body">{episode.title}</p>
        {episode.vimeo_data?.formatted_duration && (
          <span tw="text-gray-400">
            {episode.vimeo_data.formatted_duration}
          </span>
        )}
      </div>
      <div tw="sm:(flex items-start) md:block">
        <div
          tw="relative bg-brand-black flex-shrink-0 sm:(w-32) md:w-full"
          css={css`
            max-width: 380px;
          `}
        >
          <GatsbyImage
            image={image}
            alt={episode.poster.alternativeText || episode.title || ""}
            css={!canWatchNow && tw`opacity-40`}
          />
          <p
            css={[
              tw`px-2 uppercase font-display text-brand-black absolute top-0 right-0 text-xs sm:hidden md:block xl:py-1`,
              canWatchNow ? tw`bg-brand-blue` : tw`bg-brand-mist`,
            ]}
          >
            {canWatchNow ? (
              <FormattedMessage id="watch_now" defaultMessage="Watch now" />
            ) : (
              episode.formatted_release_date
            )}
          </p>
          {canWatchNow && episode.vimeo_url && (
            <button
              onClick={() =>
                dispatch({
                  type: ACTIONS.setVideo,
                  payload: {
                    fallback_url,
                    url: episode.vimeo_url,
                    ...episode.vimeo_data,
                  },
                })
              }
              tw="absolute inset-0 flex w-full items-center justify-center text-brand-blue"
            >
              <AppIcon name="play" tw="w-12 h-12 block lg:(w-16 h-16)" />
            </button>
          )}
        </div>
        <Sandbox
          tw="text-xs text-gray-500 mt-2 sm:(pl-2 mt-0) md:(pl-0 mt-2)"
          dangerouslySetInnerHTML={{ __html: episode.description }}
        />
      </div>
    </div>
  )
}

export const fragment = graphql`
  fragment EpisodeSummaryFragment on StrapiEpisode {
    id
    release_date
    formatted_release_date: release_date(formatString: "DD MMM")
    vimeo_url
    vimeo_data {
      ...VideoData
      formatted_duration
    }
    poster {
      url
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1.77777778
            width: 380
            layout: CONSTRAINED
          )
        }
      }
    }
  }
`

export default EpisodeThumb
