import React from "react"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { childVariants, parentVariants } from "./variants"

const RiceIcon = () => {
  return (
    <InView threshold={0.5}>
      {({ ref, inView }) => (
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          version="1.1"
          viewBox="0 0 200 200"
          xmlSpace="preserve"
          fill="currentColor"
          ref={ref}
          variants={parentVariants}
          initial="out"
          animate={inView ? "in" : "out"}
        >
          <motion.path
            variants={childVariants}
            d="M97 11.5h4.2v29.8H97zM158.3 155.6h-29.2c-2.5-12.7-2.4-31.6-1-47.5 8.1 1.2 15.5 3.5 24.6 6.6.3.1.5.1.8.1.8 0 1.6-.4 2-1.1.6-.9.5-2.1-.3-2.9-9.7-10.4-17.3-16.7-27.9-17.5h-.7c-.1 0-.3.1-.4.1-.1 0-.2.1-.4.2-.1.1-.3.2-.4.3l-.3.3c-.1.1-.2.2-.3.4l-.1.1c-4.1 9-7.3 20.2-9.7 31.5.9-20.5 3.4-44.4 7.1-68.5.2-1.2-.6-2.3-1.7-2.7-1.2-.3-2.4.3-2.9 1.4-5.6 13.4-9.8 30.1-12.7 50.4-2.2-24.2-7.2-46.1-14.1-60.1-.5-1-1.7-1.6-2.8-1.2-1.1.3-1.8 1.4-1.7 2.6 1.9 18 3.8 37.3 4.8 56.1-1.9-8.8-4.3-17-7.5-23.8v-.1c-.1-.1-.2-.2-.2-.3-.1-.1-.2-.2-.3-.4-.1-.1-.2-.2-.3-.2-.1-.1-.3-.2-.4-.2-.1 0-.2-.1-.4-.1s-.3-.1-.5-.1h-.2c-10 .8-20.2 6.7-30.2 17.5-.7.8-.8 2-.3 2.9.5.7 1.2 1.1 2 1.1.3 0 .5 0 .8-.1 9.8-3.3 18.6-5.4 26.7-6.6 1.4 19.1 2 42.6 1.4 61.8H48c-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1.1 2.4 2.4 2.4h110.4c1.3 0 2.4-1.1 2.4-2.4-.1-1.3-1.2-2.4-2.5-2.4zm-29.8-52.2v-.3c.2-1.4.3-2.9.5-4.3 0-.1 0-.2.1-.4 5.6 1 10.5 4 15.6 8.7-5.4-1.7-10.6-3-16.2-3.7zM79.9 89c-5.7.8-11.7 2-18 3.8C67.7 88 73.6 85 79.4 84c0 .2 0 .4.1.6.1 1.5.2 2.9.4 4.4zm5.6 11.7c4.3 17.5 6 38.3 5.7 55h-4.8c.5-17 .2-37.3-.9-55zM93 65.8c6 20.8 9.2 49.2 8.5 77.2-.2 4.1-.5 8.3-.6 12.6h-4.7c1.9-26.8-.3-59.2-3.2-89.8zm12.7 89.8c.3-3.9.4-7.9.5-11.9 1.4-22.7 3.8-42 7.5-58.3-3.1 26.8-4.3 51.7-3.5 70.2h-4.5zm9.7 0c1.3-12.2 3.8-26.9 7.4-40-.8 14-.5 29 1.4 40h-8.8z"
          />
          <motion.g variants={childVariants}>
            <path
              d="M131.3 24.8h30.9V29h-30.9z"
              transform="rotate(-53.687 146.743 26.885)"
            />
            <path
              d="M51.1 24.8h4.2v32.9h-4.2z"
              transform="rotate(-29.105 53.158 41.29)"
            />
            <path
              d="M23.4 118.5h28.7v4.2H23.4z"
              transform="rotate(-14.557 37.793 120.587)"
            />
            <path
              d="M154.3 80.4H183v4.2h-28.7z"
              transform="rotate(-14.557 168.647 82.506)"
            />
          </motion.g>
          <motion.path
            variants={childVariants}
            d="M25.6 79.4l3.6 1.6c.2.1.3.2.3.4l1.4 4c.2.6.9.6 1.1 0l1.4-4c.1-.2.2-.3.3-.4l3.6-1.6c.5-.2.5-1 0-1.3l-3.6-1.6c-.2-.1-.3-.2-.3-.4l-1.4-4c-.2-.6-.9-.6-1.1 0l-1.4 4c-.1.2-.2.3-.3.4l-3.6 1.6c-.5.3-.5 1.1 0 1.3M42.4 69.7c1.4-.6 2.1-2.3 1.5-3.7-.6-1.5-2.3-2.1-3.7-1.5-1.5.6-2.1 2.3-1.5 3.7.6 1.4 2.3 2.1 3.7 1.5M155.1 58.7l3.6-1.6c.5-.2.5-1 0-1.3l-3.6-1.6c-.2-.1-.3-.2-.3-.4l-1.4-4c-.2-.6-.9-.6-1.1 0l-1.4 4c-.1.2-.2.3-.3.4l-3.6 1.6c-.5.2-.5 1 0 1.3l3.6 1.6c.2.1.3.2.3.4l1.4 4c.2.6.9.6 1.1 0l1.4-4c.1-.2.2-.4.3-.4M163.8 47.4c1.4-.6 2.1-2.3 1.5-3.7-.6-1.5-2.3-2.1-3.7-1.5-1.5.6-2.1 2.3-1.5 3.7.5 1.4 2.2 2.1 3.7 1.5"
          />
        </motion.svg>
      )}
    </InView>
  )
}

export default RiceIcon
