import React from "react"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { childVariants, parentVariants } from "./variants"

const CameraIcon = () => {
  return (
    <InView threshold={0.5}>
      {({ ref, inView }) => (
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 167 129"
          fill="currentColor"
          ref={ref}
          variants={parentVariants}
          initial="out"
          animate={inView ? "in" : "out"}
        >
          <motion.path
            variants={childVariants}
            d="M56.5 51.5l5.1 1.2-1 4.2-5.1-1.2c-2.5-.6-5.1 1-5.7 3.5l-10.2 42.2c-.6 2.5 1 5.1 3.5 5.7l5.1 1.2-1 4.2-5.1-1.2c-4.9-1.2-7.9-6.1-6.7-11l10.2-42.2c1.1-4.7 6.1-7.7 10.9-6.6zm52.3 12.7l17.6 4.3c4.9 1.2 7.9 6.1 6.7 11l-10.2 42.2c-1.2 4.9-6.1 7.9-11 6.7l-2.5-.6 1-4.2 2.5.6c2.5.6 5.1-1 5.7-3.5l10.2-42.2c.6-2.5-1-5.1-3.5-5.7l-17.6-4.3 1.1-4.3zm-24.6 48c-1.8 0-3.5-.2-5.3-.6-12-2.9-19.3-15-16.4-26.9 1.4-5.8 5-10.7 10.1-13.8 5.1-3.1 11.1-4 16.9-2.6 12 2.9 19.3 15 16.4 26.9-1.4 5.8-5 10.7-10.1 13.8-3.5 2.1-7.5 3.2-11.6 3.2zm0-40.6c-3.3 0-6.6.9-9.5 2.7-4.2 2.5-7.1 6.6-8.2 11.3-2.4 9.8 3.7 19.7 13.5 22.1 4.8 1.1 9.7.4 13.8-2.2 4.2-2.5 7.1-6.6 8.2-11.3 2.4-9.8-3.7-19.7-13.5-22.1-1.4-.3-2.9-.5-4.3-.5zM70.9 99.8c-2.6-3.8-3.7-8.7-2.5-13.6.7-2.9 2.1-5.4 3.9-7.4-.1.5-.2 1.1-.3 1.6-1.3 1.8-2.3 4-2.9 6.3-1.1 4.6-.3 9.2 1.8 13.1zm39.6-28.1l13.8 3.3c1 .2 1.6 1.2 1.4 2.2l-1 4.3c-.2 1-1.2 1.6-2.2 1.4l-13.8-3.3c-1-.2-1.6-1.2-1.4-2.2l1-4.3c.2-1 1.2-1.6 2.2-1.4zm-42.7-4.6c1 .3 1.7 1.3 1.4 2.3-.3 1-1.3 1.7-2.3 1.4-1-.3-1.7-1.3-1.4-2.3.2-1 1.2-1.7 2.3-1.4zm14.9-14.2l-8.5 5.8-2.4-3.6 10.1-6.9 18.1 4.4-1 4.2-16.3-3.9z"
          />
          <motion.path
            fill-rule="nonzero"
            variants={childVariants}
            d="M118.9042 27.11722L137.20318 2.218l3.38436 2.48724-18.29898 24.89922-3.38436-2.48724zm-92.54901-10.231l3.66954-2.04288 16.00256 28.74473-3.66954 2.04288-16.00256-28.74473zM.62637 98.47581L28.4051 91.2635l1.05546 4.06518-27.77873 7.21231-1.05546-4.06518zm136.59912-25.40244l27.77873-7.21231 1.05546 4.06518-27.77873 7.21231-1.05546-4.06518zM80 .5h4.2v29.8H80V.5z"
          />
          <motion.path
            fill-rule="nonzero"
            variants={childVariants}
            d="M8.6 68.4c-.5-.2-.5-1 0-1.3l3.6-1.6c.1-.1.2-.2.3-.4l1.4-4c.2-.6.9-.6 1.1 0l1.4 4c0 .2.1.3.3.4l3.6 1.6c.5.3.5 1.1 0 1.3L16.7 70c-.1.1-.2.2-.3.4l-1.4 4c-.2.6-.9.6-1.1 0l-1.4-4c0-.2-.1-.3-.3-.4l-3.6-1.6zm16.8-9.7c-1.4.6-3.1-.1-3.7-1.5-.6-1.4 0-3.1 1.5-3.7 1.4-.6 3.1 0 3.7 1.5.6 1.4-.1 3.1-1.5 3.7zm112.7-11c-.1 0-.2.2-.3.4l-1.4 4c-.2.6-.9.6-1.1 0l-1.4-4c0-.2-.1-.3-.3-.4l-3.6-1.6c-.5-.3-.5-1.1 0-1.3l3.6-1.6c.1-.1.2-.2.3-.4l1.4-4c.2-.6.9-.6 1.1 0l1.4 4c0 .2.1.3.3.4l3.6 1.6c.5.3.5 1.1 0 1.3l-3.6 1.6zm8.7-11.3c-1.5.6-3.2-.1-3.7-1.5-.6-1.4 0-3.1 1.5-3.7 1.4-.6 3.1 0 3.7 1.5.6 1.4-.1 3.1-1.5 3.7z"
          />
        </motion.svg>
      )}
    </InView>
  )
}

export default CameraIcon
