import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import "twin.macro"
import { motion, AnimatePresence } from "framer-motion"
import { hasGdprCookie, setGdprCookie } from "../../utils/cookie-utils"
import { FormattedMessage } from "react-intl"
import AppIcon from "@atoms/icon"

const CookieBanner = () => {
  const [show, setShow] = useState(false)
  const setCookie = () => {
    setGdprCookie()
    setShow(false)
  }
  useEffect(() => {
    if (!hasGdprCookie()) {
      setShow(true)
    }
  }, [])
  return (
    <div tw="fixed bottom-0 right-0 p-4 z-50">
      <AnimatePresence>
        {show && (
          <motion.div
            tw="px-6 pt-6 pb-4 bg-white text-brand-black border-brand-mist border rounded-md shadow-xl relative max-w-md"
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ opacity: 0 }}
          >
            <p tw="text-xs mr-2 lg:text-sm">
              <FormattedMessage
                id="gdpr_consent__message"
                defaultMessage="This website stores data such as cookies to enable important site
              functionality including analytics. Read our privacy policy"
              />{" "}
              <Link tw="block text-brand-blue text-right" to="/privacy">
                <FormattedMessage
                  id="gdpr_consent__read"
                  defaultMessage="Read our privacy policy"
                />
              </Link>
            </p>
            <button onClick={setCookie} tw="absolute top-0 right-0 p-1">
              <AppIcon name="close" tw="w-5 h-5 block" />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default CookieBanner
