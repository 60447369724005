import tw, { css } from "twin.macro"
import { FormattedMessage } from "react-intl"
import AppIcon from "@atoms/icon"

const DrawerToggle = ({ isOpen, onClick }) => (
  <button
    css={[
      tw`absolute pr-2 pl-4 bg-white top-10 flex items-center h-10 leading-none right-full -mr-1 uppercase font-display whitespace-nowrap focus:(outline-none)`,
      css`
        transform: rotate(-90deg);
        transform-origin: bottom right;
        &:focus {
          .app-icon {
            ${tw`text-brand-blue`}
          }
        }
      `,
    ]}
    className="tear-t-sm-white"
    onClick={onClick}
  >
    <FormattedMessage id="episodes" defaultMessage="Episodes" />
    <AppIcon
      name="arrow_right"
      css={[
        tw`w-3 h-3 block ml-2`,
        isOpen
          ? css`
              transform: rotate(90deg);
            `
          : css`
              transform: rotate(-90deg);
            `,
      ]}
    />
  </button>
)

export default DrawerToggle
