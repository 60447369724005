import React, { useState, useEffect } from "react"
import tw, { css } from "twin.macro"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Headroom from "react-headroom"
import logo from "@assets/fairtrade-international-logo.svg"
import logoSymbol from "@assets/fairtrade-icon.svg"
import { motion, AnimatePresence } from "framer-motion"
import { injectIntl } from "react-intl"
import { prefixLink } from "../../../i18n/helpers"
import { Sling as Hamburger } from "hamburger-react"
import { useMediaQuery } from "../../../utils/use-media-query"
import { globalHistory } from "@reach/router"
import LocaleSwitch from "./locale-switcher"
import HeaderMenu from "./menu"
import "./header.css"

const Header = ({ intl, path }) => {
  const [state, setState] = useState({ pinned: false, unfixed: true })
  const [menuOn, setMenuOn] = useState(false)
  const changeLogoWhenMenuOpen = useMediaQuery("(max-width: 640px)")

  const toggleMenu = () => {
    setMenuOn(!menuOn)
  }

  const getLogo = showIcon => {
    return {
      key: showIcon ? "icon" : "full",
      src: showIcon ? logoSymbol : logo,
      css: showIcon
        ? [
            tw`mt-1`,
            css`
              width: 40px;
            `,
          ]
        : [
            tw`bg-white`,
            css`
              width: 82px;
              @media (min-width: 768px) {
                width: 100px;
              }
              @media (min-width: 1280px) {
                width: 105px;
              }
            `,
          ],
    }
  }

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setMenuOn(false)
    })
  }, [setMenuOn])

  return (
    <Headroom
      style={{ zIndex: 120 }}
      onPin={() => {
        setState({ pinned: true, unfixed: false })
      }}
      onUnpin={() => {
        setState({ pinned: false, unfixed: false })
      }}
      onUnfix={() => {
        setState({ pinned: false, unfixed: true })
      }}
    >
      <header
        tw="relative top-0 z-50 bg-brand-dark shadow-lg h-12"
        className="tear-b-lg-dark"
      >
        <div tw="relative mx-auto">
          <Link
            tw="block absolute left-2 top-0 z-10 sm:left-6"
            to={prefixLink("/", intl.locale)}
          >
            <AnimatePresence exitBeforeEnter>
              <motion.img
                {...getLogo(
                  state.pinned ||
                    !state.unfixed ||
                    (changeLogoWhenMenuOpen && menuOn)
                )}
                initial={{ y: "-100%" }}
                animate={{ y: 0 }}
                exit={{ y: "-100%" }}
                alt="Fairtrade International"
              />
            </AnimatePresence>
          </Link>
          <div tw="flex-grow"></div>
          <div tw="absolute top-0 h-12 right-0 flex items-center">
            <LocaleSwitch path={path} />
            <Hamburger
              label="Menu"
              color="#D4FF47"
              size={24}
              toggled={menuOn}
              toggle={toggleMenu}
            />
          </div>
        </div>
      </header>
      <HeaderMenu close={() => setMenuOn(false)} isOpen={menuOn} />
    </Headroom>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default injectIntl(Header)
