import React from "react"
import PropTypes from "prop-types"
import Header from "@organisms/header/header"
import Footer from "@organisms/footer"
import CookieBanner from "@atoms/cookie-consent"
import EpisodesDrawer from "@organisms/episodes-drawer/drawer"
import { AnimatePresence, motion } from "framer-motion"
import { IntlProvider } from "react-intl"
import { getCurrentLocale } from "../i18n/helpers"
import GlobalContextProvider from "../utils/global-context"
import VideoOverlay from "../components/molecules/video-overlay"
import { graphql, useStaticQuery } from "gatsby"

const duration = 0.5

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
}

const query = graphql`
  fragment DictionaryFragment on StrapiDictionaryItems {
    dates
    gdpr_consent__read
    gdpr_consent__message
    go_to_story
    find_out_more
    episodes
    captions_off
    info
    open_gallery
    stories
    watch_now
    locale
  }

  query LayoutQuery {
    site {
      siteMetadata {
        locales
        activeLocales
      }
    }
    strapiHeader {
      ...MenuFragment
    }
    strapiDictionary {
      items {
        ...DictionaryFragment
      }
    }
  }
`

const Layout = ({ location, children }) => {
  const url = (location && location.pathname) || ""
  const {
    strapiHeader: header,
    site: {
      siteMetadata: { locales, activeLocales },
    },
    strapiDictionary,
  } = useStaticQuery(query)

  const locale = getCurrentLocale(locales, url)
  const messages_en = strapiDictionary.items.find(item => item.locale === "en")
  let messages = messages_en

  if (locale !== "en") {
    const locale_messages = strapiDictionary.items.find(
      item => item.locale === locale
    )
    if (locale_messages) {
      for (const key in locale_messages) {
        if (Object.hasOwnProperty.call(locale_messages, key)) {
          if (!locale_messages[key]) {
            locale_messages[key] = messages_en[key]
          }
        }
      }
      messages = locale_messages
    }
  }
  const initialState = { header, locales, activeLocales }
  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <GlobalContextProvider initialState={initialState}>
        <Header path={url} />
        <AnimatePresence>
          <motion.main
            key={url}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            {children}
          </motion.main>
        </AnimatePresence>
        <Footer path={url} />
        <EpisodesDrawer />
        <VideoOverlay />
        <CookieBanner />
      </GlobalContextProvider>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
