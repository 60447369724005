import "twin.macro"
import React from "react"
import AppIcon from "@atoms/icon"

const CloseButton = ({ onClick, className }) => (
  <button
    tw="p-2 bg-black block bg-opacity-80"
    onClick={onClick}
    className={className}
  >
    <span tw="border-brand-green border-2 rounded-full block p-1 relative focus:(outline-none)">
      <AppIcon name="close" tw="w-4 h-4 block text-brand-green" />
    </span>
  </button>
)

export default CloseButton
