import { defaultLocale } from "./config"

export const getStoryPrefixed = (slug, locale) => {
  if (locale === "en") return `/story/${slug}`
  return prefixLink(`/${slug}`, locale)
}

export const prefixLink = function (url, locale) {
  if (locale === defaultLocale) return url
  return `/${locale}${url}`
}

export const isValidLocale = function (locales, locale) {
  return Array.isArray(locales)
    ? locales.includes(locale)
    : locale === defaultLocale
}

export const getCurrentLocale = function (locales, url) {
  if (typeof url === "string") {
    const _locale = url.split("/")[1]
    return _locale && isValidLocale(locales, _locale) ? _locale : defaultLocale
  }
  return defaultLocale
}

export const getBrowserLanguage = () => {
  if (typeof window === "undefined") {
    return null
  }

  const first = window.navigator.languages
    ? window.navigator.languages[0]
    : null

  const lang =
    first ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage

  return lang
}

export const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return "en"
  }
  const lang =
    navigator && navigator.language && navigator.language.split("-")[0]
  if (!lang) return "en"

  switch (lang) {
    case "es":
      return "es"
    case "de":
      return "de"
    case "fr":
      return "fr"
    case "it":
      return "it"
    case "pl":
      return "pl"
    default:
      return "en"
  }
}
