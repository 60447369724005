import React from "react"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { childVariants, parentVariants } from "./variants"

const FistIcon = ({ useInView = true }) => {
  return (
    <InView threshold={0.5}>
      {({ ref, inView }) => (
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          version="1.1"
          viewBox="0 0 200 200"
          xmlSpace="preserve"
          ref={ref}
          variants={parentVariants}
          {...(useInView
            ? {
                initial: "out",
                animate: inView ? "in" : "out",
              }
            : {})}
          fill="currentColor"
        >
          <motion.path
            variants={childVariants}
            d="M145.5 100.4c0-5.5-1.6-9.9-4.9-13-.2-.1-.3-.3-.5-.4V64.6c0-7.1-5.5-11.4-10.8-11.4-3.2 0-6.1 1.4-8.2 4-.6.7-1.1 1.5-1.5 2.4-.5-.9-1-1.7-1.6-2.4-2.3-2.7-5.4-4.3-8.7-4.3h-.1c-3.8 0-7.8 2.1-10.1 6.6-2.5-5.2-6.8-7.9-10.9-7.9-5 0-10.8 4.1-12 15.2-3.4-2.4-7.2-3.2-10.7-2-5.3 1.8-8.6 7.4-9 15.5-.2 4.3-.4 8.3-.5 11.9h-.1c0 .6 0 1.2.1 1.8-.7 23.8 1.5 33.7 15 44.7 13.3 10.8 4.7 32.6 4.6 32.9l3.8 1.6c.4-1 9.8-24.9-5.9-37.6-10.1-8.2-13.2-15.1-13.5-32.4 2.1 1.9 4.8 3 7.6 3 3.2 0 6.3-1.3 8.6-3.6.7-.7 1.4-1.6 2-2.7.1.2.2.5.4.7 1.6 2.6 5.3 4.4 11.3 5.5-1.7 1.8-3.7 4.4-5.2 7.6-5.7 11.9-1.9 23.7 2.2 31.5 9.3 17.5-3.9 40.1-4 40.3l3.6 2.1c.6-1 14.6-24.9 4.1-44.4-11.4-21.4 1.2-33.8 4.6-36.5 1.3.1 2.6.2 4 .3 2.7.1 5.3.2 7.6.2-4.9 2.9-11.6 11.3-11.8 20.1-.2 8.7 5.7 14.2 10.7 17.3 4.1 2.6 9.7 8.3 12.7 20.8 1.5 6 1.8 11.6 1.9 14.1v.5c0 1.1 0 2.6 1.6 3l1.7.4.7-1.6c.1-.2.1-.5.1-1v-.1-1c0-.6 0-1.4.1-2.5.7-14.2 5.2-19.5 12-27.6l1.5-1.8c7.8-9.3 7.6-22.6 7.5-39.3v-8.1m-21.1-40.6c1.3-1.6 3-2.5 4.9-2.5 4.2 0 6.6 3.7 6.6 7.2v19.7c-4-1.9-9-2.7-14.2-3v-12c.1-4 1-7.3 2.7-9.4m-15.1-2.7c4 0 8.3 3.8 8.4 12.1v11.9c-2.9 0-5.9.1-8.7.2-1.9.1-3.7.1-5.5.1h-2.4V69.2c0-8.3 4.2-12.1 8.2-12.1m-21.1-1.3c4.3 0 8.7 5 8.8 13.4v12.3H83.9c-1.4 0-2.7.4-3.8 1V71.4c0-7.7 2.5-15.5 8.1-15.6M67.7 102c-2 0-3.9-.8-5.2-2.2-1.3-1.4-2.1-3.4-2.4-5.9.1-4 .3-8.3.5-13.2.4-8.9 4.4-11.2 6.1-11.8 2.9-1 6.4.4 9.1 3.4v20.1c.1 4.7-2.7 9.6-8.1 9.6m67.2 43.2l-1.5 1.8c-4.8 5.7-8.7 10.3-10.9 17.5-2.7-11-7.7-19.1-14.5-23.4-6-3.7-8.9-8.3-8.8-13.7.2-7.9 6.6-14.9 9.9-16.7l14.5-8.5-8.8.6c-11.9.8-29.9.1-32.5-4.3-2.6-4.4-2.5-8.7-1.3-10.9.7-1.2 1.7-1.8 2.9-1.8h4c2.4.1 4.8.1 15.7 0 1.8 0 3.6-.1 5.5-.2 10.7-.4 22.8-.8 28.6 4.9 2.4 2.4 3.6 5.6 3.6 10 0 2.8 0 5.5.1 8.2.2 15.7.3 28.3-6.5 36.5"
          />
          <motion.path
            variants={childVariants}
            d="M123.5 118.2c-3.4-.5-6.8.3-9.6 2.3-2.8 2-4.6 5-5.2 8.4-1.1 7 3.7 13.6 10.7 14.8.7.1 1.4.2 2.1.2 2.7 0 5.3-.8 7.5-2.4 2.8-2 4.6-5 5.2-8.4 1.1-7.1-3.6-13.7-10.7-14.9m6.6 14.1c-.4 2.3-1.6 4.3-3.5 5.7-1.9 1.4-4.2 1.9-6.5 1.5-4.7-.8-8-5.2-7.2-10 .4-2.3 1.6-4.3 3.5-5.7 1.5-1.1 3.3-1.7 5.1-1.7.5 0 .9 0 1.4.1 4.7.9 8 5.4 7.2 10.1M25.6 79.4l3.6 1.6c.2.1.3.2.3.4l1.4 4c.2.6.9.6 1.1 0l1.4-4c.1-.2.2-.3.3-.4l3.6-1.6c.5-.2.5-1 0-1.3l-3.6-1.6c-.2-.1-.3-.2-.3-.4l-1.4-4c-.2-.6-.9-.6-1.1 0l-1.4 4c-.1.2-.2.3-.3.4l-3.6 1.6c-.5.3-.5 1.1 0 1.3M42.4 69.7c1.4-.6 2.1-2.3 1.5-3.7-.6-1.5-2.3-2.1-3.7-1.5-1.5.6-2.1 2.3-1.5 3.7.6 1.4 2.3 2.1 3.7 1.5M155.1 58.7l3.6-1.6c.5-.2.5-1 0-1.3l-3.6-1.6c-.2-.1-.3-.2-.3-.4l-1.4-4c-.2-.6-.9-.6-1.1 0l-1.4 4c-.1.2-.2.3-.3.4l-3.6 1.6c-.5.2-.5 1 0 1.3l3.6 1.6c.2.1.3.2.3.4l1.4 4c.2.6.9.6 1.1 0l1.4-4c.1-.2.2-.4.3-.4M163.8 47.4c1.4-.6 2.1-2.3 1.5-3.7-.6-1.5-2.3-2.1-3.7-1.5-1.5.6-2.1 2.3-1.5 3.7.5 1.4 2.2 2.1 3.7 1.5"
          />
          <motion.g variants={childVariants}>
            <path
              d="M131.3 24.8h30.9V29h-30.9z"
              transform="rotate(-53.687 146.743 26.885)"
            />
            <path
              d="M51.1 24.8h4.2v32.9h-4.2z"
              transform="rotate(-29.105 53.158 41.29)"
            />
            <path
              d="M17.7 105.8h28.7v4.2H17.7z"
              transform="rotate(-14.557 32.063 107.862)"
            />
            <path
              d="M154.3 80.4H183v4.2h-28.7z"
              transform="rotate(-14.557 168.647 82.506)"
            />
            <path d="M97 11.5h4.2v29.8H97z" />
          </motion.g>
        </motion.svg>
      )}
    </InView>
  )
}

export default FistIcon
