import React from "react"
import { InView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { childVariants, parentVariants } from "./variants"

const CoconutIcon = () => (
  <InView threshold={0.5}>
    {({ ref, inView }) => (
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        fill="currentColor"
        ref={ref}
        variants={parentVariants}
        initial="out"
        animate={inView ? "in" : "out"}
      >
        <motion.path
          variants={childVariants}
          d="M138.9 131.8c-10.4 13.6-31.2 21.8-47.4 23.9-.2 0-.3 0-.5.1-10 1.3-18.2.2-22.1-2.8-5-3.8-8.7-16.8-8.9-31.5-.3-16.8 3.8-32.3 10.9-41.6C78.4 69.9 87.3 64.3 97 63c10.2-1.4 21.3 2.2 32.4 10.7 21.6 16.5 25.1 37.7 9.5 58.1zm-7-61.4c-23.3-17.8-47.4-15.2-64.3 6.9-17.3 22.7-13.5 69.5-1.2 78.9 4.9 3.8 13.8 5.1 25.2 3.7.2 0 .3 0 .5-.1 17.1-2.3 39.1-11 50.1-25.5 16.9-22.2 13-46.1-10.3-63.9z"
        />
        <motion.path
          variants={childVariants}
          d="M75.3 86.7c-.6 1.1-13.9 28.4-2.5 59.2.3.9 1.3 1.4 2.2 1.3.1 0 .3-.1.4-.1 1.1-.4 1.6-1.6 1.2-2.6-10.8-29.1 2.2-55.7 2.3-55.9.5-1 .1-2.2-.9-2.8-.9-.5-2.2-.1-2.7.9zM104.7 73.2c2.6-.3 5 1.5 5.3 4.1.3 2.6-1.5 5-4.1 5.3-2.6.3-5-1.5-5.3-4.1-.3-2.6 1.5-5 4.1-5.3zM119.1 73.9c2.6-.3 5 1.5 5.3 4.1.3 2.6-1.5 5-4.1 5.3-2.6.3-5-1.5-5.3-4.1-.3-2.6 1.5-5 4.1-5.3zM110.5 85.1c2.6-.3 5 1.5 5.3 4.1.3 2.6-1.5 5-4.1 5.3-2.6.3-5-1.5-5.3-4.1-.3-2.5 1.5-4.9 4.1-5.3z"
        />
        <motion.path
          variants={childVariants}
          d="M135.923 47.108l18.299-24.9 3.384 2.488-18.299 24.899zM43.366 36.884l3.67-2.043 16.002 28.745-3.67 2.043zM17.632 118.476l27.78-7.213 1.055 4.065-27.78 7.213zM154.231 93.073l27.78-7.213 1.055 4.065-27.78 7.213zM97 20.5h4.2v29.8H97z"
        />
        <motion.path
          variants={childVariants}
          d="M25.6 88.4l3.6 1.6c.2.1.3.2.3.4l1.4 4c.2.6.9.6 1.1 0l1.4-4c.1-.2.2-.3.3-.4l3.6-1.6c.5-.2.5-1 0-1.3l-3.6-1.6c-.2-.1-.3-.2-.3-.4l-1.4-4c-.2-.6-.9-.6-1.1 0l-1.4 4c-.1.2-.2.3-.3.4l-3.6 1.6c-.5.3-.5 1.1 0 1.3M42.4 78.7c1.4-.6 2.1-2.3 1.5-3.7-.6-1.5-2.3-2.1-3.7-1.5-1.5.6-2.1 2.3-1.5 3.7.6 1.4 2.3 2.1 3.7 1.5M155.1 67.7l3.6-1.6c.5-.2.5-1 0-1.3l-3.6-1.6c-.2-.1-.3-.2-.3-.4l-1.4-4c-.2-.6-.9-.6-1.1 0l-1.4 4c-.1.2-.2.3-.3.4l-3.6 1.6c-.5.2-.5 1 0 1.3l3.6 1.6c.2.1.3.2.3.4l1.4 4c.2.6.9.6 1.1 0l1.4-4c.1-.2.2-.4.3-.4M163.8 56.4c1.4-.6 2.1-2.3 1.5-3.7-.6-1.5-2.3-2.1-3.7-1.5-1.5.6-2.1 2.3-1.5 3.7.5 1.4 2.2 2.1 3.7 1.5"
        />
      </motion.svg>
    )}
  </InView>
)

export default CoconutIcon
