import { useEffect, useState } from "react"
import _debounce from "lodash/debounce"

const useBreakpoints = (breakpoints, baseValue) => {
  const [state, setState] = useState(baseValue)
  useEffect(() => {
    const onResize = _debounce(() => {
      const matched = breakpoints.some(bp => {
        if (window.matchMedia(bp.query).matches) {
          setState(bp.value)
          return true
        }
        return false
      })
      if (!matched) {
        setState(baseValue)
      }
    }, 100)
    window.addEventListener("resize", onResize)
    window.addEventListener("orientationchange", onResize)

    onResize()

    return () => {
      ;["resize", "orientationchange"].forEach(ev => {
        window.removeEventListener(ev, onResize)
      })
    }
  })
  return state
}

export default useBreakpoints
