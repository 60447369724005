import { useEffect } from "react"
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

export const enableSL = target => {
  if (!target) return
  document.documentElement.style.overflow = "hidden"
  disableBodyScroll(target)
}
export const clearSl = () => {
  clearAllBodyScrollLocks()
  document.documentElement.style.overflow = null
}
const useBodySrollLock = (target, skipClear) => {
  useEffect(() => {
    enableSL(target)
    return () => {
      if (!skipClear) {
        clearSl()
      }
    }
  }, [target, skipClear])
}

export default useBodySrollLock
