import { graphql, Link } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { prefixLink, getStoryPrefixed } from "../../../i18n/helpers"
import { useIntl, FormattedMessage } from "react-intl"
import tw, { css, styled } from "twin.macro"
import SimpleBar from "simplebar-react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { clearSl, enableSL } from "../../../utils/use-body-scroll-lock"
import BlueArrow from "../../atoms/blue-arrow"
import AppIcon from "../../atoms/icon"
import { motion } from "framer-motion"
import { GlobalStateContext } from "../../../utils/global-context"

const MenuTitle = tw.h3`
  font-display font-black text-brand-green text-2xl uppercase
  md:(text-4xl mb-4)
  lg:(text-5xl)
  xl:(text-7xl)
`

const CharacterPortrait = styled(GatsbyImage)`
  ${tw`!overflow-hidden !rounded-full flex-shrink-0 w-16 h-16 relative`}
  img {
    ${tw`rounded-full`}
  }
  @media (min-width: 1024px) {
    width: 80px;
    height: 80px;
  }
`

const CharacterThumb = ({ story }) => {
  const thumbnail = getImage(story.character_thumbnail.localFile)
  return (
    <div tw="flex items-center py-2">
      <div tw="p-1 rounded-full border border-white border-opacity-25">
        <CharacterPortrait
          image={thumbnail}
          alt={
            story.character_thumbnail.alternativeText ||
            story.character_name ||
            ""
          }
        />
      </div>
      <div tw="pl-2 border-l border-brand-green ml-6 lg:ml-10">
        <p tw="text-brand-green leading-none font-thin text-sm lg:text-base">
          {story.location}
        </p>
        <div tw="flex items-center">
          <p tw="font-display font-black text-white text-3xl leading-none uppercase lg:text-4xl">
            {story.character_name}
          </p>
          {story.released && <BlueArrow />}
        </div>
      </div>
    </div>
  )
}

const HeaderMenu = ({ close, isOpen }) => {
  const intl = useIntl()
  const { header } = useContext(GlobalStateContext)
  const [target, setTarget] = useState()
  useEffect(() => {
    if (isOpen && target) {
      enableSL(target)
    } else {
      clearSl()
    }
    return () => clearSl()
  }, [isOpen, target])
  if (!header?.items?.length) return <></>
  let data = header.items.find(item => item.locale === intl.locale)
  if (!data) {
    data = header.items.find(item => item.locale === "en")
  }
  const items = ["about", "meetTheFilmakers", "getInvolved"]
    .map(key => {
      return data[key]
    })
    .filter(x => !!x)

  const stories = data.stories

  return (
    <motion.div
      css={[tw`absolute bg-brand-dark w-full px-6 pt-6`]}
      className="tear-b-lg-dark"
      initial="out"
      animate={isOpen ? "in" : "out"}
      variants={{
        out: { opacity: 0, y: "-100%" },
        in: { opacity: 1, y: 0 },
      }}
    >
      <SimpleBar
        tw="overflow-y-auto overflow-x-hidden h-full"
        autoHide={true}
        css={css`
          max-height: calc(85vh - 96px);
          .simplebar-scrollbar:before {
            ${tw`bg-brand-black`}
          }
        `}
        scrollableNodeProps={{ ref: setTarget }}
      >
        <div tw="pb-12 sm:(flex justify-center) md:px-6">
          <div tw="sm:px-6 lg:px-12 xl:px-20">
            <MenuTitle>
              <FormattedMessage id="stories" defaultMessage="Stories" />
            </MenuTitle>
            <ul>
              {(stories || []).map(story => (
                <li
                  key={`${story.slug}_${story.id}`}
                  style={{ opacity: story.released ? 1 : 0.5 }}
                >
                  <Link
                    to={getStoryPrefixed(story.slug, intl.locale)}
                    title={story.name}
                    activeClassName="is-active"
                    css={[!story.released && tw`pointer-events-none`]}
                  >
                    <CharacterThumb story={story} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div tw="mt-6 sm:(mt-0 px-6) lg:px-12 xl:px-20">
            <MenuTitle>
              <FormattedMessage id="info" defaultMessage="info" />
            </MenuTitle>
            <ul tw="sm:(mt-6 pt-1) md:(mt-10 pt-1) lg:pt-2">
              {items.map(item => (
                <li key={`${item.__typename}_${item.id}`} tw="mb-4 lg:py-2">
                  <Link
                    to={prefixLink(`/${item.slug}`, intl.locale)}
                    title={item.name}
                    tw="flex items-center text-white font-display text-2xl sm:text-3xl xl:text-4xl"
                    css={[
                      css`
                        &.is-active {
                          ${tw`text-brand-green`}
                        }
                      `,
                    ]}
                    activeClassName="is-active"
                  >
                    <span>{item.name}</span>
                    <BlueArrow tw="flex-shrink-0 ml-4" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </SimpleBar>
      <span
        className="gradient-bottom-after is-dark"
        tw="block absolute bottom-12 left-0 right-0 h-12 pointer-events-none"
      ></span>
      <button
        tw="block relative w-10 h-10 mx-auto focus:outline-none text-white text-opacity-50 mt-2"
        onClick={close}
      >
        <AppIcon name="drag_handle" tw="block w-full h-full" />
      </button>
    </motion.div>
  )
}

export const query = graphql`
  fragment MenuFragment on StrapiHeader {
    items {
      locale
      meetTheFilmakers {
        __typename
        slug
        name
        id
      }
      getInvolved {
        __typename
        id
        name
        slug
      }
      about {
        __typename
        id
        name
        slug
      }
      stories {
        character_name
        id
        location
        name
        released
        slug
        character_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1, width: 80, layout: CONSTRAINED)
            }
          }
        }
        localizations {
          locale
          id
        }
      }
    }
  }
`
export default HeaderMenu
