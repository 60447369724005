import "twin.macro"
import AppIcon from "./icon"
const BlueArrow = ({ ...props }) => (
  <span
    tw="p-1 ml-2 block bg-brand-blue text-white border-4 rounded-full"
    {...props}
  >
    <AppIcon name="arrow_right" tw="w-3 h-3 block" />
  </span>
)
export default BlueArrow
