import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import tw, { styled } from "twin.macro"
import { Link } from "gatsby"
import EpisodeThumb from "./episode-thumb"
import { useIntl, FormattedMessage } from "react-intl"
import { getStoryPrefixed } from "../../../i18n/helpers"
import AppIcon from "../../atoms/icon"

const CharacterPortrait = styled(GatsbyImage)`
  ${tw`!overflow-hidden !rounded-full flex-shrink-0 w-14 h-14 lg:(w-16 h-16)`}
  img {
    ${tw`rounded-full`}
  }
`

const DrawerStory = ({ story }) => {
  const thumbnail = getImage(story.character_thumbnail.localFile)
  const { locale } = useIntl()
  return (
    <>
      <Link
        to={getStoryPrefixed(story.slug, locale)}
        tw="flex items-center py-4"
      >
        <CharacterPortrait
          image={thumbnail}
          alt={
            story.character_thumbnail.alternativeText ||
            story.character_name ||
            ""
          }
        />
        <div tw="pl-2">
          <p tw="text-brand-blue leading-none">{story.location}</p>
          <p tw="font-display font-black text-brand-black text-3xl leading-none uppercase md:text-xl xl:text-3xl">
            {story.character_name}
          </p>
          <span tw="flex items-center text-sm leading-none">
            <FormattedMessage defaultMessage="Full Story" id="go_to_story" />
            <AppIcon
              name="arrow-forward"
              tw="block w-2 h-2 ml-1 text-brand-blue"
            />
          </span>
        </div>
      </Link>
      {story.episodes.map(episode => (
        <EpisodeThumb
          episode={episode}
          fallback_url={story.fallback_url}
          key={episode.id}
        />
      ))}
    </>
  )
}

export default DrawerStory
