import tw, { styled } from "twin.macro"

const Sandbox = styled.div`
  p {
    ${tw`mb-4`};
    &:last-child {
      ${tw`mb-2`}
    }
  }
  strong,
  b {
    ${tw`font-bold`};
  }
  a {
    ${tw`text-brand-blue`};
  }
  ul {
    ${tw`my-4`};
    list-style-type: disc;
    padding-left: 16px;
  }
  h4 {
    ${tw`text-lg font-bold`};
  }

  &.text-columns--2,
  &.text-columns--3 {
    column-width: 280px;
    column-fill: balance;
    column-gap: 2em;
  }
  &.text-columns--2 {
    column-count: 2;
  }
  &.text-columns--3 {
    column-count: 3;
  }

  &.text-size--smaller {
    ${tw`font-light`}
    font-size: 0.95em;
    line-height: 1.4;
  }
`

export default Sandbox
